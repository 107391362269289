import constate from 'constate';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { AllRoutesKeys } from '@/core/navigation/use-navigation';
import { STORAGE_KEY_LUMA } from '@/services/local-storage/keys';

export type CurrentPageContext = {
  pageName: string;
  keyElements: string[];
  availableActions: string[];
  userGoals: string[];
  key: AllRoutesKeys;
};

const mapInfoByPathname: Record<string, CurrentPageContext> = {
  '/app/[appid]/dashboard': {
    key: 'dashboard',
    pageName: 'Dashboard',
    keyElements: [],
    availableActions: [],
    userGoals: ['Overview of most used devices', 'Overview of most used analyses', 'Overview of automations'],
  },
  '/app/[appid]/automations': {
    key: 'automations',
    pageName: 'Automations',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
  '/app/[appid]/automations/[automationid]': {
    key: 'automation-detail',
    pageName: 'automation detail',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
  '/app/[appid]/areas': {
    key: 'areas',
    pageName: 'Areas',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
  '/app/[appid]/areas/[areaid]': {
    key: 'area-detail',
    pageName: 'Area detail',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
  '/app/[appid]/areas/[areaid]/[deviceid]': {
    key: 'device-detail',
    pageName: 'Device detail',
    keyElements: [],
    availableActions: [],
    userGoals: ['Device data detail chart by timeframe'],
  },
  '/app/[appid]/analysis': {
    key: 'analysis',
    pageName: 'Analysis',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
  '/app/[appid]/analysis/analysisslug': {
    key: 'analysis-detail',
    pageName: 'Analysis detail',
    keyElements: [],
    availableActions: [],
    userGoals: [],
  },
};

type DeviceData = {
  deviceName: string;
  sampleData: Array<Record<string, string | number>>;
}

const useLuma = () => {
  const router = useRouter();
  const [pageContext, setPageContext] = useState<CurrentPageContext>();
  const [deviceData, setDeviceData] = useState<DeviceData>();
  const [isLumaOpened, setIsLumaOpened] = useLocalstorageState<boolean>(STORAGE_KEY_LUMA, false);

  useEffect(() => {
    if (mapInfoByPathname[router.pathname]) {
      setPageContext(mapInfoByPathname[router.pathname]);
    }
  }, [router.pathname]);

  return {
    pageContext,
    setPageContext,
    deviceData,
    setDeviceData,
    isLumaOpened,
    setIsLumaOpened,
  };
};

export const [
  LumaContextProvider,
  useLumaContext,
] = constate(useLuma);
