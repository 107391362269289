import {
  Drawer, Panel,
  useResponsiveContext,
} from '@lualtek/react-components';

import { Luma } from '@/components/luma/desktop/chat';
import {
  LumaLogo,
} from '@/components/luma/shared';
import { useLumaContext } from '@/context/use-luma-context';

import { useLumaWrapperLogic } from '../../shared/use-luma-wrapper-logic';
import styles from './luma-wrapper.module.css';

export type LumaWrapperProps = {
  loading?: boolean;
}
export const LumaWrapper: FCChildren<LumaWrapperProps> = () => {
  const { matches } = useResponsiveContext();
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const { lumaProps } = useLumaWrapperLogic();

  if (isLumaOpened && matches.extraLarge) {
    return (
      <Panel
        bordered
        radius={24}
        vibrant
        showGlow
        glowSpread={200}
        rainbowColors
        className={styles.ChatPanel}
      >
        <Luma {...lumaProps} />
      </Panel>
    );
  }

  return (
    <Drawer
      isOpen={isLumaOpened}
      onClose={() => setIsLumaOpened(false)}
      side="left"
      heading={<LumaLogo dimension="big" />}
      maxWidth="700px"
      showHeader={false}
    >
      <div className={styles.ChatPanel}>
        <Luma {...lumaProps} />
      </div>
    </Drawer>
  );
};

