import { useChat } from 'ai/react';
import { useMemo } from 'react';

import { useGetChatMessages, useOrganizationChatParamsSubs } from '@/api/chat';
import { useFeatureLimits } from '@/api/organization';
import { useUser } from '@/api/user';
import { useInfoContext } from '@/context/use-info-context';
import { getHeaders } from '@/services/apollo/get-headers';

export const useLumaWrapperLogic = () => {
  const { user } = useUser();
  const { info } = useInfoContext();

  const { featureLimits, featureLimitsParsed } = useFeatureLimits();
  const {
    // error,
    organizationChatParams,
    loading: loadingTokens,
  } = useOrganizationChatParamsSubs({
    organizationId: info?.currentOrganizationId ?? 0,
  });
  const hasTokens = useMemo(
    () => (Number(featureLimits?.maxChatTokensMonth) - Number(organizationChatParams?.tokensMonthUsed ?? 0)) > 0,
    [featureLimits, organizationChatParams?.tokensMonthUsed],
  );
  const {
    chatMessages,
    lastFetchChatMessages,
    loading: isHistoryLoading,
    fetchMoreMessages,
    loadingFetchMore,
    // refetch,
  } = useGetChatMessages({
    userId: user?.id ?? 0,
    organizationId: info?.currentOrganizationId ?? 0,
    maxChatHistoryMessages: featureLimitsParsed.maxChatHistoryMessages,
  });

  // const { chatMessages: chatMessageSub } = useGetChatMessagesSub({
  //   organizationId: info?.currentOrganizationId ?? 0,
  //   userId: user?.id ?? 0,
  // });

  const chatHelpers = useChat({
    api: '/api/ai/chat',
    body: {
      // TODO toti poi ne parliamo
      // pageContext,
      // deviceData
      userId: user?.id ?? 0,
      organizationId: info?.currentOrganizationId ?? 0,
    },
    headers: {
      ...getHeaders(),
      'x-hasura-user-id': String(user?.id) ?? '',
    },
    experimental_throttle: 150,
  });

  const isLastMessageFromAssistant = useMemo(() => {
    const lastMessage = chatHelpers.messages[chatHelpers.messages.length - 1];
    return lastMessage?.role === 'assistant';
  },
  [chatHelpers.messages]);

  const lumaProps = useMemo(() => ({
    featureLimits,
    hasTokens,
    loadingTokens,
    lastFetchHistoryMessages: lastFetchChatMessages,
    isHistoryLoading,
    historyMessages: chatMessages,
    fetchMoreMessages,
    loadingFetchMore,
    isAssistantStartToGenerate: chatHelpers.isLoading && !isLastMessageFromAssistant,
    chatHelpers,
    organizationChatParams,
  }), [
    featureLimits,
    hasTokens,
    loadingTokens,
    lastFetchChatMessages,
    isHistoryLoading,
    chatMessages,
    fetchMoreMessages,
    loadingFetchMore,
    isLastMessageFromAssistant,
    chatHelpers,
    organizationChatParams,
  ]);

  return {
    lumaProps,
  };
};
