import {
  Drawer,
} from '@lualtek/react-components';

import { NavLink } from '@/components/common/mobile/navlink';
import { Luma } from '@/components/luma/mobile/chat';
import {
  LumaLogo,
} from '@/components/luma/shared';
import { useLumaContext } from '@/context/use-luma-context';

import { useLumaWrapperLogic } from '../../shared/use-luma-wrapper-logic';

export type LumaWrapperProps = {
  loading?: boolean;
}
export const LumaWrapper: FCChildren<LumaWrapperProps> = () => {
  const { isLumaOpened, setIsLumaOpened } = useLumaContext();
  const { lumaProps } = useLumaWrapperLogic();

  return (
    <>
      <NavLink
        onClick={() => setIsLumaOpened(true)}
        icon="ai-chat"
      />
      <Drawer
        isOpen={isLumaOpened}
        onClose={() => setIsLumaOpened(false)}
        side="right"
        compactHeader
        heading={<LumaLogo dimension="regular" />}
        maxWidth="700px"
      >
        <Luma {...lumaProps} />
      </Drawer>
    </>
  );
};

