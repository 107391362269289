import { Separator, Stack, Text } from '@lualtek/react-components';
import { UseChatHelpers } from 'ai/react/dist';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import {
  LumaBalloon, LumaInput, LumaNoTokens, LumaPresets,
} from '@/components/luma/shared';
import { useMobileContext } from '@/context/use-mobile-context';
import { Trans, useTranslate } from '@/core/i18n';
import { OrganizationFeatureLimitsFragment } from '@/generated/graphql';

import { LumaInputData } from './input/input';

type UseLumaComponentsProps = {
  onClickPreset: (query: string) => Promise<void>;
  queryPresets: string[];
  shouldShowAllPresets: boolean;
  onExpandPreset: () => void;
  hasTokens?: boolean;
  shouldAlert: boolean;
  onSubmit?: (data: LumaInputData) => void;
  hasMessages: boolean;
  chatHelpers: UseChatHelpers;
  featureLimits: OrganizationFeatureLimitsFragment | null | undefined;
}

export const useLumaComponents = ({
  onClickPreset,
  queryPresets,
  shouldShowAllPresets,
  onExpandPreset,
  hasTokens,
  shouldAlert,
  onSubmit,
  hasMessages,
  chatHelpers: {
    messages, isLoading, stop,
  },
  featureLimits,
}: UseLumaComponentsProps) => {
  const { t } = useTranslate();
  const { isMobile } = useMobileContext();

  const Presets = useMemo(() => (
    <LumaPresets
      presets={queryPresets}
      visiblePresets={4}
      presetsSize="small"
      onClick={onClickPreset}
      shouldShowAllPresets={shouldShowAllPresets}
      onExpand={onExpandPreset}
    />
  ), [onClickPreset, onExpandPreset, queryPresets, shouldShowAllPresets]);

  const Messages = useMemo(() => messages.map(m => (
    <LumaBalloon
      key={m.id}
      isResponse={m.role !== 'user'}
      datetime={m.createdAt?.toISOString()}
    >
      {m.content}
    </LumaBalloon>
  )), [messages]);

  const Disclaimer = useMemo(() => (isMobile ? (
    <Stack hAlign="center" fill={false} hPadding={16} rowGap={8}>
      <Text size={12} align="center" dimmed={4}>
        {t('luma:disclaimer')}
        {' '}
        <Text textColor="var(--highlight-green-foreground)">beta</Text>
      </Text>
    </Stack>
  ) : (
    <Stack hAlign="center" fill={false} vPadding={4} rowGap={8}>
      <Text size={12} dimmed={4}>
        {t('luma:disclaimer')}
        {' '}
        <Text textColor="var(--highlight-green-foreground)">beta</Text>
      </Text>
    </Stack>
  )), [t, isMobile]);

  const Input = useMemo(() => {
    if (isMobile) {
      if (hasTokens) {
        return (
          <Stack hPadding={16} vPadding={8} rowGap={8}>
            {shouldAlert && (
              <Text size={14} sentiment="warning" lineHeight="small" align="center">
                {t('luma:messages.fewTokensLeft')}
              </Text>
            )}
            <LumaInput
              onSubmit={onSubmit}
              onStop={stop}
              disabled={isLoading}
              rows={hasMessages ? 1 : 5}
              loading={isLoading}
              maxHeight={120}
            />
          </Stack>
        );
      }

      return (
        <Stack hPadding={16} vPadding={24}>
          <LumaNoTokens />
        </Stack>
      );
    }

    if (hasTokens) {
      return (
        <Stack hPadding={24} vPadding={16} rowGap={8}>
          {shouldAlert && (
            <Text size={14} sentiment="warning" lineHeight="small" align="center">
              {t('luma:messages.fewTokensLeft')}
            </Text>
          )}
          <LumaInput
            onSubmit={onSubmit}
            onStop={stop}
            disabled={isLoading}
            rows={hasMessages ? 1 : 7}
            loading={isLoading}
          />
        </Stack>
      );
    }

    return (
      <Stack hPadding={24} vPadding={24}>
        <LumaNoTokens />
      </Stack>
    );
  }, [isMobile, hasTokens, isLoading, hasMessages, shouldAlert, t, onSubmit, stop]);

  const HistoryLimitReachedMessage = useMemo(
    () => messages.length === Number(featureLimits?.maxChatHistoryMessages ?? 0)
   && (
     <>
       <Text as="li" align="center" size={14} lineHeight="small" dimmed={5} style={{ width: '100%' }}>
         <Trans i18nKey="luma:messages.historyLimit" components={[<br />]} />
       </Text>
       <Separator vPadding={8} style={{ width: '100%' }} />
     </>
   ), [
      messages,
      featureLimits,
    ],
  );

  const InfoStateActions = useMemo(() => match({
    hasQueryPresets: queryPresets.length > 0,
    hasTokens,
  }).with({ hasQueryPresets: true, hasTokens: true }, () => Presets).otherwise(() => null),
  [queryPresets.length, hasTokens, Presets]);

  return {
    Presets,
    Messages,
    Disclaimer,
    Input,
    HistoryLimitReachedMessage,
    InfoStateActions,
  };
};
