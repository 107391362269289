import { useResponsiveContext } from '@lualtek/react-components';
import { Message } from 'ai';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useInViewRef } from 'rooks';
import { PickDeep } from 'type-fest';

import { useLumaContext } from '@/context/use-luma-context';
import { useTranslate } from '@/core/i18n';

import { LumaProps } from './luma.type';
import { calculateTokensUsage, mapChatMessages } from './luma-helpers';
import { useLumaScroller } from './use-luma-scroller';

type UseLumaLogicProps = PickDeep<
LumaProps,
'historyMessages'
| 'lastFetchHistoryMessages'
| 'featureLimits'
| 'organizationChatParams'
| 'chatHelpers'
| 'loadingFetchMore'
| 'fetchMoreMessages'>;

export const useLumaLogic = ({
  historyMessages,
  lastFetchHistoryMessages,
  featureLimits,
  organizationChatParams,
  loadingFetchMore,
  fetchMoreMessages,
  chatHelpers: {
    messages, append, setMessages, isLoading,
  },
}: UseLumaLogicProps) => {
  const { t } = useTranslate();
  const { matches } = useResponsiveContext();
  const { pageContext } = useLumaContext();
  const hasMessages = useMemo(() => messages.length > 0, [messages]);
  const messagesRef = useRef<HTMLDivElement>(null);
  const [shouldShowAllPresets, setShouldShowAllPresets] = useState(false);
  const [innerMessagesRef, isMessagesRefInView] = useInViewRef();
  const [oldScrollHeight, setOldScrollHeight] = useState(0);
  const [isShowJumpButton, setIsShowJumpButton] = useState(false);

  const queryPresets: string[] = useMemo(() => t(`luma:presets.${pageContext?.key}`, {}, { returnObjects: true }), [t, pageContext]);

  const chatHistoryMap: Message[] = useMemo(
    () => mapChatMessages(historyMessages),
    [historyMessages],
  );
  const chatHistoryLastMessagesMap: Message[] = useMemo(
    () => mapChatMessages(lastFetchHistoryMessages),
    [lastFetchHistoryMessages],
  );

  const {
    shouldAlert,
  } = useMemo(() => calculateTokensUsage({
    maxTokensChatMonth: Number(featureLimits?.maxChatTokensMonth),
    tokensMonthUsed: Number(organizationChatParams?.tokensMonthUsed),
  }), [featureLimits, organizationChatParams]);

  const onExpandPreset = useCallback(() => {
    setShouldShowAllPresets(true);
  },
  [setShouldShowAllPresets]);

  const onSubmit = useCallback(async ({ input }: {
    input: string;
  }) => {
    await append({
      role: 'user',
      content: input,
      createdAt: new Date(),
    });
  }, [append]);

  const onClickPreset = useCallback(async (query: string) => {
    await append({
      role: 'user',
      content: query,
      createdAt: new Date(),
    });
  },
  [append]);

  const handleJumpToBottom = useCallback(() => {
    messagesRef.current?.scrollTo({ top: messagesRef.current.scrollHeight, behavior: 'smooth' });
  }, [messagesRef]);

  useEffect(() => {
    if (chatHistoryMap.length > 0 && chatHistoryLastMessagesMap.length === 0) {
      setMessages(chatHistoryMap);
    }
  }, [chatHistoryMap, setMessages, chatHistoryLastMessagesMap]);

  useEffect(() => {
    if (chatHistoryLastMessagesMap.length > 0) {
      setMessages(messages => [...chatHistoryLastMessagesMap, ...messages]);
    }
  }, [chatHistoryLastMessagesMap, setMessages]);

  /**
     * Handling scroll to bottom when chat is shown
     * */
  useEffect(() => {
    if (messagesRef.current && isMessagesRefInView) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [
    matches.extraLarge,
    isMessagesRefInView,
    messagesRef,
  ]);

  /**
     * Handling scroll to bottom when assistant is responding
     * */
  useEffect(() => {
    if (messagesRef.current && isLoading) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [
    messages,
    isLoading,
    messagesRef,
  ]);

  /**
     * Handling correct old scroll position on loading fetch more
     */
  useEffect(() => {
    if (!loadingFetchMore && oldScrollHeight && messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight - oldScrollHeight;
    }
  }, [loadingFetchMore, oldScrollHeight, messagesRef]);

  /**
 * Handling correct number of messages in chat, removing the oldest ones
 * after luma response
 */
  useEffect(() => {
    if (!isLoading && !loadingFetchMore) {
      setMessages(messages => messages.slice(-Number(featureLimits?.maxChatHistoryMessages ?? 0)));
    }
  }, [isLoading, setMessages, loadingFetchMore, featureLimits]);

  useLumaScroller(messagesRef, (status) => {
    setIsShowJumpButton(Boolean(status));
  }, { kind: 'jumptobottom' });

  useLumaScroller(messagesRef, () => {
    setOldScrollHeight(messagesRef.current?.scrollHeight ?? 0);
    // handling correct offset avoiding duplicates from fetch, if user writes a message
    void fetchMoreMessages({ offset: messages.length });
  }, { kind: 'fetchmore' });

  return {
    shouldAlert,
    hasMessages,
    queryPresets,
    onSubmit,
    onClickPreset,
    onExpandPreset,
    shouldShowAllPresets,
    messagesRef,
    handleJumpToBottom,
    matches,
    innerMessagesRef,
    isMessagesRefInView,
    oldScrollHeight,
    isShowJumpButton,
  };
};
