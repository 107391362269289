import {
  IconButton, Stack, Textarea,
  TextareaProps,
} from '@lualtek/react-components';
import clsx from 'clsx';
import {
  ChangeEvent, FormEvent, useCallback,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { useBoundingclientrect, useMergeRefs } from 'rooks';
import { Except } from 'type-fest';

import { useTranslate } from '@/core/i18n';

import styles from './input.module.css';

export type LumaInputData = {
  input: string;
}

export type LumaInputProps = Partial<Except <TextareaProps, 'onSubmit'>> & {
  maxHeight?: number;
  rows?: TextareaProps['rows'];
  onSubmit?: (data: LumaInputData) => void;
  disabled?: boolean;
  onStop?: () => void;
  loading?: boolean;
}

const MAX_LUMA_INPUT = 8000;

export const LumaInput: FCClass<LumaInputProps> = ({
  className,
  maxHeight = 265,
  disabled,
  onInput,
  onSubmit,
  onStop,
  loading,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const rect = useBoundingclientrect(textareaRef);
  const {
    register,
    reset,
    handleSubmit: handleSubmitForm,
  } = useForm<LumaInputData>();
  const { ref, ...registerRest } = register('input', { required: true });
  const refs = useMergeRefs(textareaRef, ref);

  const handleInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    onInput?.(e);
    if (textareaRef.current) {
      // Reset height
      textareaRef.current.style.height = 'auto';
      const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
      // Set dynamic height
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [onInput, maxHeight]);

  const handleSubmit = useCallback((data: LumaInputData) => {
    onSubmit?.(data);
    reset();
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  }, [onSubmit, reset]);

  return (
    <Stack
      as="form"
      fill={false}
      className={clsx(styles.Input, className)}
      onSubmit={handleSubmitForm(handleSubmit)}
      onKeyDown={(e) => {
        if (!loading && e.key === 'Enter' && !e.shiftKey && !disabled) {
          e.preventDefault();
          void handleSubmitForm(handleSubmit)(e as FormEvent);
        }
      }}
    >
      <Textarea
        {...otherProps}
        label=""
        aria-label={t('luma:input.label')}
        placeholder={t('luma:input.label')}
        ref={refs}
        onInput={handleInput}
        maxLength={MAX_LUMA_INPUT}
        style={{
          height: rect ? rect?.height : 'auto',
        }}
        {...registerRest}
      />
      <Stack direction="row" columnGap={8} fill={false} className={styles.SendButton}>
        {disabled && <IconButton icon="remove" kind="secondary" sentiment="warning" onClick={onStop} />}
        {!disabled && (
          <IconButton
            type="submit"
            icon="arrow-right"
            aria-label={t('luma:input.sendLabel')}
            disabled={disabled}
          />
        )}
      </Stack>
    </Stack>
  );
};

LumaInput.displayName = 'LumaInput';
